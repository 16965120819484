.picture-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
  }
  
  .picture-button-title {
    margin: 0;
    font-size: 24px;
    text-align: center;
  }
  
  .picture-button-image {
    width: 28vw;
    max-height: 200px;
    object-fit: cover;
    cursor: pointer;
  }
  .all-caps {
    text-transform: uppercase;
}
.green_color {
    color: #8bc34a;
  }

  @media screen and (max-width: 768px) {
    .picture-button-image {
      width: 90vw;
      max-height: 150px;
      object-fit: cover;
      cursor: pointer;
    }

      
  .picture-button-title {
    margin: 0;
    font-size: 14px;
    text-align: center;
  }
  }
  
  