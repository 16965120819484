.hero-container {
  position: relative;
  margin-bottom: 5px;
  z-index: 1;
  margin-top: 0px;
}

.hero-image {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.cta-button {
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #8bc34a;
}

.featured-title {
  text-align: center;
  margin-bottom: 10px;
}

.featured-title h2 {
  font-size: 28px;
  color: #558b2f; /* Change title color to a shade of green */
  margin: 0;
  padding-bottom: 5px;
  border-bottom: 3px solid #558b2f; /* Add a bottom border for emphasis */
  display: inline-block;
}

.statistics-section {
  background-color: #f5f5f5;
  padding: 10px 0;
}

.statistics-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.statistic {
  flex: 0 0 calc(33.33% - 40px);
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.statistic:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.section-divider {
  height: 2px;
  background-color: #ddd;
  width: 60px;
  margin: 20px auto;
}

.call-to-action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.cta-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.cta-button:hover {
  background-color: #4caf50;
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.testimonial-slider h2 {
 color:#558b2f
}
.testimonial-slider {
  background-color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
  overflow: hidden; /* Hide overflow to ensure smooth transition */
}

.testimonial {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Add opacity transition */
}

/* Apply the fade effect */
.testimonial p {
  font-size: 18px;
  color: #333;
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Add opacity transition */
}

.testimonial-author {
  font-size: 16px;
  color: #666;
  margin-top: 10px;
}

/* Fade out the current testimonial and fade in the next */
.testimonial.fade-out {
  opacity: 0;
}

.testimonial.fade-in {
  opacity: 1;
}

.faq-section {
  padding: 10px 0;
}

.faq-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.faq-item:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.faq-item h3 {
  font-size: 20px;
  color: #333;
  cursor: pointer;
  margin-bottom: 10px;
}

.faq-item p {
  font-size: 16px;
  color: #666;
  display: none;
}

.faq-item.active {
  background-color: #e0e0e0;
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.faq-item.active h3 {
  color: #8bc34a; /* Change question color on active */
}

.faq-item.active p {
  display: block;
}



.hero-introduction {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
  max-width: 60%;
  animation: fade-in 1s ease-in-out forwards;
  max-width: 100%; /* Adjust as needed */
}

.hero-text {
  font-size: 24px;
  color: #fff;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}

.hero-topic {
  font-size: 24px;
  color: #90EE90;
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-textbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  text-align: center;
  width: 50%;
}

.visual-storytelling {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.story-image {
  width: 30%;
  overflow: hidden;
  border-radius: 8px;
}

.story-image img {
  width: 100%;
  height: auto;
}

.featured-content {
  margin-top: 20px;
}

.content-card {
  background-color: #e6b516;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card-content {
  display: flex;
  justify-content: space-around; /* Distributes space between the images */
  align-items: center;
}

.content-card img {
  width: 180px; /* Adjust the size as needed */
  height: 180px; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
}


.text-content {
  flex: 1;
  padding: 0 20px;
  text-align: left;
}
/* Update .read-more-link styles */

.read-more-link {
  color: #558b2f; /* Change link color to a shade of green */
  text-decoration: none;
  margin-top: 10px;
  font-weight: bold;
  transition: color 0.2s, text-decoration 0.2s;
}

.read-more-link:hover {
  text-decoration: none;
  color: #4caf50; /* Change link color on hover to a slightly different green */
  border-bottom: 2px solid #4caf50; /* Add a bottom border on hover for underline effect */
}


.content-card h3 {
  font-size: 18px;
  color: #fff;
  margin-top: 10px;
}

.content-card p {
  font-size: 14px;
  color: #fff;
  margin-top: 10px;
}

.newsletter-section {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 10px;
  padding: 40px 0;
  text-align: center;
}

.newsletter-section h2 {
  font-size: 28px;
  color: #558b2f;
  margin: 0 0 10px;
}

.newsletter-section p {
  font-size: 16px;
  color: #555;
  margin: 0 0 20px;
}

.newsletter-section form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsletter-section input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 10px;
  width: 300px;
}

.newsletter-section button {
  background-color: #558b2f;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-section button:hover {
  background-color: #8bc34a;
}

.calendar-section {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin: 5px;
  padding: 40px 0;
  text-align: center;
}

.calendar-section h2 {
  font-size: 28px;
  color: #558b2f;
  margin: 0 0 10px;
}

.calendar-section p {
  font-size: 16px;
  color: #555;
  margin: 0 0 20px;
}

.react-calendar {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile--active {
  background-color: #8bc34a;
  color: #fff;
}

.react-calendar__tile--hasActive {
  background-color: #558b2f;
}

.react-calendar__tile--hasActive:hover {
  background-color: #6b9b3d;
}

.react-calendar__tile--now {
  background-color: #e6b516;
  color: #fff;
  font-weight: bold;
}

.calendar-event {
  font-size: 12px; /* Adjust the font size as needed */
  color: #558b2f; /* Set initial color */
  background-color: transparent;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
}

.calendar-event:hover,
.calendar-event.active {
  color: #fff; /* Change color on hover or click */
  background-color: #558b2f;
}





.hero-buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
  width: 100vw;
  height: 48vh;
  padding: 0 20px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  border-bottom: none;
}

.hero-buttons .hero-button-link {
  text-decoration: none;
  color: black;
}

@media screen and (max-width: 768px) {
  .hero-image {
    width: 100vw;
    height: 25vh;
    object-fit: inherit;
  }

  .hero-textbox {
    width: 80vw;
    height: 15vh;
    object-fit: inherit;
  }

  .hero-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 20px;
    width: 100vw;
    font-size: 14px;
  }

  .hero-buttons .hero-button-link {
    margin-bottom: 10px;
    width: 70vw;
  }

  .hero-container {
    height: auto;
    margin-bottom: 0;
  }
  .hero-topic{
    font-size: 1.0rem;
  }
  .hero-text{
    font-size: 0.8rem;
  }
.hero-introduction{
  font-size: 0.6rem;
}

.statistics-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.statistic {
  flex: 0 0 calc(50% - 40px);
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  transition: transform 0.2s, box-shadow 0.2s;
}

.statistic:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-slider {
  background-color: #f5f5f5;
  padding: 20px 0;
  text-align: center;
  overflow: hidden; /* Hide overflow to ensure smooth transition */
}

.testimonial {
  max-width: 600px;
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Add opacity transition */
}

.cta-button {
  background-color: #e6b516;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.2s, transform 0.2s, box-shadow 0.2s;
}

.featured-content {
  margin-top: 3rem;
}

.featured-title {
  text-align: center;
  margin-bottom: 10px;
}

.faq-section {
  padding: 10px 0;
  margin: 10px
}

.faq-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.content-card {
  background-color: #e6b516;
  padding: 20px;
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.card-content {
  display: flex;
  justify-content: space-around; /* Distributes space between the images */
  align-items: center;
}

.content-card img {
  width: 70px; /* Adjust the size as needed */
  height:70px; /* Maintain aspect ratio */
  object-fit: cover;
  border-radius: 50%;
  margin: 10px;
}
}



  
  