.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), #e6b516);
  padding: 10px 50px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
}

.navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.navbar-logo img {
  height: 50px;
  padding: 1px;
}

.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 10;
}

.navbar-item {
  margin: 0 10px;
  position: relative;
}

.navbar-link {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  padding: 10px;
  transition: color 0.2s ease-in-out;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
}

.navbar-link:hover {
  color: #558b2f;
}

.navbar-right {
  display: flex;
}

.menu-icon {
  display: none;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 1000;
}

.menu-icon i {
  font-size: inherit;
  font-weight: inherit;
  z-index: 10;
}

.documents-link-container {
  position: relative;
  margin-top: -1%;
}

.dropdown-menu {
  display: none;
  position: relative;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
  list-style: none;
  left: 0;
  top: 100%;
  width: 500px;
}

.documents-link-container:hover .dropdown-menu {
  display: block;
}

.documents-link-container .dropdown-menu {
  position: relative;
  left: auto;
  top: auto;
  margin: 10px;
  padding: 10px;
  box-shadow: none;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), #e6b516);
    padding: 5px 5px;
  }

  .navbar-logo img {
    height: 30px;
    padding: 1px;
  }

  .menu-icon {
    display: block;
  }

  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    width: 90vw;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeInDown 0.3s ease;
  }

  .navbar-item {
    margin: 0;
    background-color: white;
  }

  .navbar-link {
    display: block;
    padding: 12px 20px;
  }

  .navbar-link:hover {
    color: white;
    background-color: #8bc34a;
  }

  .navbar-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .navbar-menu.show {
    display: flex;
  }

  .documents-link-container .dropdown-menu {
    position: relative;
    left: auto;
    top: auto;
    margin: 0;
    padding: 0;
    box-shadow: none;
    background-color: white;
  }

  .navbar-item:hover .dropdown-menu {
    display: block;
  }
}
