.page-container {
    background: linear-gradient(to bottom,#e6b516,rgba(255, 255, 255, 0.2),#e6b516);
    padding-top: 30px;
  }

  /* Add custom styles to the multi-select dropdown */
.form-select[multiple] {
  height: auto;
  max-height: 120px; /* Set a maximum height */
  overflow-y: auto; /* Hide the scrollbar and show only if needed */
}

.mpesa-payment-widgets {
  text-align: center; /* Center-align the text content */
  border: 1px solid var(--mpesa-green);
  padding: 10px;
  /* margin: 10px 0; */
  margin: 70%;
  border-radius: 5px;
  /* background-color: var(--mpesa-white); */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 20px;
}


  .registration-form {
    max-width: 600px;
    margin: 10px auto;
    padding: 50px;
    background-color: rgba(129, 199, 132, 0.2);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    font-family: 'Montserrat', sans-serif;
  }

  
  .registration-form h2 {
    text-align: center;
    margin-bottom: 30px;
    font-size: 36px;
    color: #8bc34a;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  }
  
  .registration-form label {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .registration-form input.form-control {
    border-radius: 30px;
    border: none;
    border: 2px solid #8bc34a;
    box-shadow: none;
    font-size: 18px;
    padding: 10px 20px;
    margin-bottom: 20px;
    transition: all 0.2s ease-in-out;
  }

  
  .registration-form input.form-control:focus {
    outline: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-color: #6aae32;
  }
  
  .registration-form .feedback {
    font-size: 14px;
  }
  
  .registration-form .btn {
    background-color: #8bc34a;
    border: none;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    padding: 12px 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    justify-self: center
  }
  
  .registration-form .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #6aae32;
  }
  .slide-1 {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically (optional) */
  }
  
  /* Common button styles */
  .slide-1 .btn {
    border: none;
    align-self: center;
    border-radius: 30px;
    font-size: 20px;
    background-color: #8bc34a;
    font-weight: bold;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    margin-top: 30px;
  }
  
  /* Specific styles for LOGIN button */
  .slide-1 .btn-login {
    background-color: #8bc34a;
    padding: 15px 45px;
    margin-right: 100px; /* Add margin to the right for spacing */
  }
  
  /* Specific styles for REGISTER button */
  .slide-1 .btn-register {
    background-color: #8bc34a;
    padding: 14px 30px; /* Adjust padding as needed */
  }
  
  /* Hover effects */
  .btn-login:hover, .btn-register:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #6aae32;
  }  
  
  /* Hover effects */
  .slide-1 .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #6aae32;
  }
  
  

  /* split page into two halves */
.page-container {
    display: flex;
    flex-direction: row;
  }
  
  .left-half {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .right-half {
    flex: 1;
    padding: 20px;
    overflow-y: scroll;
  }
  

  .slide {
    flex: 1;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /* buttons */
  .slide button {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    background-color: #8bc34a;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  /* registration form */
  #registration-form {
    display: none;
  }
  
  #registration-form.active {
    display: block;
  }

  .register-image {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .register-image img {
    max-width: 80%; /* set the maximum width to 200px */
    height: auto;
  }

  .button-container {
    display: flex;
    justify-content: center;
  }
  
  .download-button {
    padding: 10px 20px;
    border: none;
    background-color: #8bc34a;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .download-button:hover {
    background-color: #005aa3;
  }
  
  
  @media screen and (max-width: 768px) {
      /* Common button styles */
  .slide-1 .btn {
    border: none;
    align-self: center;
    border-radius: 30px;
    font-size: 20px;
    background-color: #8bc34a;
    font-weight: bold;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    margin-top: 30px;
  }
  
  /* Specific styles for LOGIN button */
  .slide-1 .btn-login {
    background-color: #8bc34a;
    padding: 15px 32px;
    margin-right: 30px; /* Add margin to the right for spacing */
  }
  
  /* Specific styles for REGISTER button */
  .slide-1 .btn-register {
    background-color: #8bc34a;
    padding: 15px 14px; /* Adjust padding as needed */
  }
  
  /* Hover effects */
  .btn-login:hover, .btn-register:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #6aae32;
  }  
  
  /* Hover effects */
  .slide-1 .btn:hover {
    transform: translateY(-3px);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
    background-color: #6aae32;
  }

    .registration-form {
      max-width: 500px;
      margin: 10px auto;
      padding: 30px;
      background-color: rgba(129, 199, 132, 0.2);
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
      font-family: 'Montserrat', sans-serif;
    }

    registration-form h2 {
      text-align: center;
      margin-bottom: 10px;
      font-size: 6px;
      color: #8bc34a;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }

    .registration-form label {
      font-size: 14px;
      color: #555;
      margin-bottom: 5px;
    }

    .registration-form input.form-control {
      border-radius: 30px;
      border: none;
      border: 2px solid #8bc34a;
      box-shadow: none;
      font-size: 14px;
      padding: 10px 20px;
      margin-bottom: 20px;
      transition: all 0.2s ease-in-out;
    }


  .registration-form .btn {
    background-color: #8bc34a;
    border: none;
    border-radius: 30px;
    font-size: 14px;
    font-weight: bold;
    padding: 12px 25px;
    margin-top: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease-in-out;
    justify-self: center
  }

  .register-image img {
    max-width: 30%; /* set the maximum width to 200px */
    height: auto;
  }

  }  