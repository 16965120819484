/* Global styles for slide container */
.slide-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 50px;
}

.slide-container h3 {
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  white-space: normal;
}

.slide-container p {
  font-size: 1.2rem;
  font-weight: 400;
  white-space: normal;
}

.images-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.achievement-image,
.mission-image {
  max-width: 300px;
  height: 300px;
  margin: 0 10px;
  border: 3px solid #ff9900;
  border-radius: 8px;
}

.navbar-logo-side img {
  height: 160px;
  padding: 1px;
}

/* Styles for small screens */
@media screen and (max-width: 768px) {
  .slide-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px; /* Reduced padding */
  }

  .slide-container h3 {
    font-size: 1.5rem; /* Reduced font size */
    margin-bottom: 0.5rem; /* Reduced margin */
  }

  .slide-container p {
    font-size: 1rem; /* Reduced font size */
  }

  .slide-container strong {
   margin-left: 10px;
  }

  .navbar-logo-side img {
    height: 60px;
  }

  .images-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .achievement-image,
  .mission-image {
    max-width: 80%; /* Adjust the maximum width to 80% of the container */
    height: auto; /* Adjust height to maintain aspect ratio */
    margin: 10px 0; /* Adjust margin to add space vertically */
    border: 2px solid #ff9900; /* Reduced border width */
  }
}

/* Specific styles for each slide if needed */
@media screen and (max-width: 768px) {
  .slide-container.history-slide p {
    font-size: 0.7rem; /* Adjust font size for history slide */
  }

  .slide-container.mission-slide p {
    font-size: 0.9rem; /* Adjust font size for mission slide */
  }

  .slide-container.achievement-slide p {
    font-size: 0.8rem; /* Adjust font size for achievement slide */
  }
}
